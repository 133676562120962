import React, { useState, useEffect } from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/layout'
import { useLanguages } from '../hooks/useLanguages'
import Helmet from 'react-helmet'

export const query = graphql`
  query aireReportsQuery {
    wpcontent {
      researchReports(first: 9, where: { orderby: { field: TITLE, order: ASC } }) {
        nodes {
          publishedYear
          region
          reportThumbnail
          uri
          title
          slug
          id
        }
      }
      seo {
        contentTypes {
          researchReport {
            metaDesc
            title
          }
        }
      }
    }
  }
`

const AireReports = ({ location, data }) => {
  const [year, setYear] = useState('ALL')
  const [country, setCountry] = useState('ALL')

  const reports = data.wpcontent.researchReports.nodes

  const [currLanguage] = useLanguages()
  const [filteredReports, setFilteredReports] = useState(reports)

  useEffect(() => {
    // Filter reports based on the year and country
    const newFilteredReports = reports.filter((report) => {
      return (
        (year === 'ALL' && country === 'ALL') ||
        (year !== 'ALL' && country === 'ALL' && report.publishedYear === year) ||
        (year === 'ALL' && country !== 'ALL' && report.region === country) ||
        (report.publishedYear === year && report.region === country)
      )
    })
    setFilteredReports(newFilteredReports)
  }, [year, country, reports])

  const ReportCard = ({ report }) => (
    <a href={report.uri}>
      <div className='cursor-pointer rounded-xl shadow bg-white p-5 relative h-auto min-h-550px xl:min-h-600px hover:scale-110 transform transition duration-500 my-9 md:my-2 lg:my-0'>
        <img src={report.reportThumbnail} alt={report.title} />
        <p
          className='m-0 pb-1 pt-4 uppercase'
          style={{
            color: '#b4b4b4 !important',
            lineHeight: '32px',
            fontWeight: '500',
            letterSpacing: '2.5px',
            fontSize: '15px',
            opacity: '0.85',
          }}
        >
          {report.region}
        </p>
        <h2 className='homepage-small-heading truncate text-line-clamp-2 overflow-hidden text-gray-500'>
          {report.title}
        </h2>
        <a className='pt-10 text-orange xl:absolute bottom-6' href={report.uri}>
          Download
        </a>
      </div>
    </a>
  )

  return (
    <Layout location={location} pageLanguage={currLanguage}>
      <Helmet>
        <title>Recognition and Rewards Reports | Vantage Circle</title>

        <meta
          name='description'
          content="Explore the latest advancements in rewards and recognition with Vantage Circle's Annual Recognition and Rewards Reports, featuring expert analyses and tailored insights for diverse markets."
        />

        <meta property='og:title' content='Recognition and Rewards Reports | Vantage Circle' />

        <meta
          property='og:description'
          content="Explore the latest advancements in rewards and recognition with Vantage Circle's Annual Recognition and Rewards Reports, featuring expert analyses and tailored insights for diverse markets."
        />

        <meta property='twitter:title' content='Recognition and Rewards Reports | Vantage Circle' />

        <meta
          property='twitter:description'
          content="Explore the latest advancements in rewards and recognition with Vantage Circle's Annual Recognition and Rewards Reports, featuring expert analyses and tailored insights for diverse markets."
        />

        <meta property='og:type' content='website' />

        <meta property='og:url' content='https://www.vantagecircle.com/recognition-and-rewards-reports/' />

        <meta property='og:site_name' content='Vantage Circle' />
        <link rel='canonical' href='https://www.vantagecircle.com/recognition-and-rewards-reports/'></link>
      </Helmet>

      <section className='w-full 2xl:max-w-7xl mx-auto bg-purple-100 lg:flex px-5 md:px-10 lg:px-20'>
        <div className='lg:w-8/12 py-10 xl:py-16'>
          <div className='mt-2 mb-1'>
            <h2
              className='pl-1 text-center lg:text-left heroTagStyle'
              style={{ color: '#64637f' }}
            >
              AIR<sup>e</sup> R&R REPORTS
            </h2>
          </div>
          <h1 className='homepage-heading pb-4 text-center lg:text-left'>
            Annual <span className='text-orange'>Recognition & Rewards</span> Reports
          </h1>
          <p className='pt-2 text-gray-500 text-center lg:text-left'>
            Explore the latest advancements in rewards and recognition with our Annual Report, featuring expert analyses and tailored insights for diverse markets.
          </p>
        </div>
        <div className='flex justify-center items-end'>
          <div className='flex justify-center items-end mt-6 pt-4 lg:mt-0'>
            <picture>
              <source
                className='z-10 relative'
                srcSet='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/08/Aire-listing-hero.webp'
                media='(min-width: 640px)'
                type='image/webp'
                alt='Vantage Perks'
                width='500'
              />
              <img
                className='z-10 relative'
                src='https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/08/Aire-listing-hero.webp'
                alt='Vantage Perks'
                width='300'
              />
            </picture>
          </div>
        </div>
      </section>

      <section className='w-full lg:max-w-7xl mx-auto pt-10 pb-5 xl:pt-16 xl:pb-10 px-5 xl:px-3'>
        <div className='flex flex-wrap lg:flex-nowrap'>
          <div className='w-full lg:w-3/4 flex flex-wrap lg:flex-nowrap'>
            <p
              className={`cursor-pointer rounded-full border py-2 px-5 mx-2 ${year === 'ALL' ? 'bg-indigo-100 text-purple-100' : ''}`}
              onClick={() => setYear('ALL')}
            >
              All
            </p>
            <p
              className={`cursor-pointer rounded-full border py-2 px-5 mx-2 ${year === '2024' ? 'bg-indigo-100 text-purple-100' : ''}`}
              onClick={() => setYear('2024')}
            >
              2024
            </p>
            <p
              className={`cursor-pointer rounded-full border py-2 px-5 mx-2 ${year === '2023' ? 'bg-indigo-100 text-purple-100' : ''}`}
              onClick={() => setYear('2023')}
            >
              2023
            </p>
            <p
              className={`cursor-pointer rounded-full border py-2 px-5 mx-2 ${year === '2022' ? 'bg-indigo-100 text-purple-100' : ''}`}
              onClick={() => setYear('2022')}
            >
              2022
            </p>

            <div className='lg:hidden lg:w-1/2 mx-2'>
              <select
                className='rounded-full py-2'
                style={{ marginTop: '2px', border: '1px solid #646470' }}
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value='ALL'>ALL</option>
                <option value='US'>US</option>
                <option value='UK'>UK</option>
                <option value='UAE'>UAE</option>
                <option value='INDIA'>INDIA</option>
                <option value='GLOBAL'>GLOBAL</option>
              </select>
            </div>
          </div>
          <div className='w-full lg:w-1/4 hidden lg:flex justify-end'>
            <div className='lg:w-1/2'>
              <select
                className='rounded-full border border-gray-1 py-2 h-12'
                onChange={(e) => setCountry(e.target.value)}
              >
                <option value='ALL'>ALL</option>
                <option value='US'>US</option>
                <option value='UK'>UK</option>
                <option value='UAE'>UAE</option>
                <option value='INDIA'>INDIA</option>
                <option value='GLOBAL'>GLOBAL</option>
              </select>
            </div>
          </div>
        </div>

        {/* Check if filteredReports is empty */}
        {filteredReports.length === 0 ? (
          <h2 className='text-gray-500 text-center homepage-mini-heading py-10 xl:pt-16'>No reports found! Let’s fine-tune your search for better results.</h2>
        ) : (
          <div className='md:grid grid-cols-2 lg:grid-cols-3 gap-6 xl:gap-10 mt-10 md:mt-5 xl:mt-10 lg:px-5 xl:px-2'>
            {filteredReports.map((report) => (
              <ReportCard key={report.id} report={report} />
            ))}
          </div>
        )}
      </section>

      <section className='pb-10 md:py-10 w-full px-5 lg:px-0'>
        <div className='w-full lg:w-11/12 lg:max-w-7xl mx-auto bg-indigo-100 lg:flex justify-around rounded-xl p-7 pt-10 pb-0 lg:px-5 xl:p-0'>
          <div className='my-auto grid justify-center lg:justify-start'>
            <h2 className='homepage-div-heading text-purple-100 text-center lg:text-left'>
              Take AIR<sup>e</sup> Assessment today
            </h2>
            <p className='text-purple-100 pt-3 pb-5 text-center lg:text-left'>
              And receive a data-backed quality score for your RnR program.
            </p>
            <a
              href='https://www.vantagecircle.com/tools/aire-score-assessment/'
              className='text-center rounded-full p-4 text-purple-100 border border-purple-100 lg:w-1/2'
            >
              Assess my program <span className='text-lightgreen text-sm font-bold'> &nbsp; &nbsp;FREE!</span>
            </a>
          </div>

          <div className='flex items-end justify-center pt-10 lg:pt-3'>
            <picture>
              <source
                className='z-10 relative'
                srcSet='https://res.cloudinary.com/vantagecircle/image/upload/gatsbycms/uploads/2024/06/AIRe-Assesment-banner.webp'
                media='(min-width: 640px)'
                type='image/webp'
                alt='Vantage Perks'
                width='450'
              />
              <img
                className='mx-auto mt-2 z-10'
                src='https://res.cloudinary.com/vantagecircle/image/upload/w_450/q_auto/gatsbycms/uploads/2024/06/AIRe-Assesment-banner.webp'
                alt='Vantage Perks'
                width='300'
              />
            </picture>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AireReports
